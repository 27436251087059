<template>
<!--    -->
    <div :class="potentialDetail ?  'potentialDetail' : 'potentialDetailPlc'">
        <rx-nav-bar title="潜房详情" return-page="PotentialHouseList" ></rx-nav-bar>
        <div v-for="item in house" :key="item.id">
            <div class="owner-info">业主信息</div>
            <!-- 业主信息-->
            <div class="owner-infos">
                <div class="leftTopValid" v-if="item.resourceStatus==1">有效</div>
                <div class="leftTopInvalid" v-if="item.resourceStatus==2">无效</div>
                <div class="leftTopsign" v-if="item.resourceStatus==3">已签</div>
                <div class="text-line">
                    <div class="text-left">
                        <span class="span1">姓名：</span>
                        <span class="span2">{{ item.userName ? item.userName : '暂无'}}</span>
                    </div>
                    <div class="text-right">
                        <span class="span1">电话：</span>
                        <span class="span2">{{ item.mobile ? item.mobile : '暂无' }}</span>
                    </div>
                </div>
                <div class="text-line">
                    <div class="text-left">
                        <span class="span1">推荐人：</span>
                        <span class="span2">{{ item.recommendUserName ? item.recommendUserName : '暂无'}}</span>
                    </div>
                    <div class="text-right">
                        <span class="span1">来源：</span>
                        <span class="span2">{{ item.potentialHouseSourceName ? item.potentialHouseSourceName : '暂无'}}</span>
                    </div>
                </div>
            </div>
            <!-- 房源信息-->
            <div class="house-info">房源信息</div>
            <div class="house-infos">
                <div class="house-area">
                    <!--<span class="span1">房源地址：</span>-->
                    房源地址：
                    <span class="span2">{{ item.address ?item.address : '暂无'}}</span>
                </div>
                <div class="infos" v-if="item.bedroomCount||item.livingroomCount||item.kitchenCount||item.bathroomCount||item.balconyCount">
                    <span class="span1">户型：</span>
                    <span class="span2">{{ item.bedroomCount ? item.bedroomCount:'0'}}室</span><span class="span3">|</span>
                    <span class="span2">{{ item.livingroomCount ? item.livingroomCount :'0'}}厅</span><span class="span3">|</span>
                    <span class="span2">{{ item.kitchenCount ? item.kitchenCount :'0'}}厨房</span><span class="span3">|</span>
                    <span class="span2">{{ item.bathroomCount ?item.bathroomCount : '0' }}卫</span><span class="span3">|</span>
                    <span class="span2">{{ item.balconyCount ? item.balconyCount : '0'}}阳台</span>
                </div>
                <div class="infos" v-else>
                    <span class="span1">户型：</span>
                    <span class="span2">暂无</span>
                </div>
                <div class="text-line">
                    <div class="text-left">
                        <span class="span1">商圈：</span>
                        <span class="span2">{{ item.businessCircleName ? item.businessCircleName : '暂无' }}</span>
                    </div>
                    <div class="text-right">
                        <span class="span1">小区名：</span>
                        <span class="span2">{{ item.estateName ? item.estateName : '暂无' }}</span>
                    </div>
                </div>
                <div class="text-line">
                    <div class="text-left">
                        <span class="span1">潜房类型：</span>
                        <span class="span2" v-if="item.resourceBelog==0">私盘</span>
                        <span class="span2" v-else>公盘</span>
                    </div>
                    <div class="text-right">
                        <span class="span1">状态：</span>
                        <span class="span2">{{ item.labelText ? item.labelText : '暂无' }}</span>
                    </div>
                </div>
                <div class="text-line">
                    <div class="text-left">
                        <span class="span1">装修程度：</span>
                        <span class="span2">{{ item.decorationDegreeName ? item .decorationDegreeName : '暂无'}}</span>
                    </div>
                    <div class="text-right">
                        <span class="span1">看房方式：</span>
                        <span class="span2">{{ item.seeHouseName ? item.seeHouseName : '暂无' }}</span>
                    </div>
                </div>
                <div class="text-line">
                    <div class="text-left">
                        <span class="span1">出租类型：</span>
                        <span class="span2">{{ item.rentTypeName ? item.rentTypeName : '暂无'}}</span>
                    </div>
                    <div class="text-right">
                        <span class="span1">最短租期：</span>
                        <span class="span2">{{ item.minRentTimeName ? item.minRentTimeName : '暂无'}}</span>
                    </div>
                </div>
                <div class="text-line">
                    <div class="text-left">
                        <span class="span1">租金：</span>
                        <span class="span2">{{ item.rentMoney ?item.rentMoney+'元/月': '暂无'}}</span>
                    </div>
                    <div class="text-right">
                        <span class="span1">交房日期：</span>
                        <span class="span2">{{ item.houseExpectedTime ? item.houseExpectedTime : '暂无'}}</span>
                    </div>
                </div>
                <div class="text-line">
                    <div class="text-left">
                        <span class="span1">楼层：</span>
                        <span class="span2">{{ item.floor ? item.floor : '暂无' }}/{{ item.totalFloor ? item.totalFloor : '暂无' }}</span>
                    </div>
                    <div class="text-right">
                        <span class="span1">房屋来源：</span>
                        <span class="span2">{{ item.potentialHouseSourceName ? item.potentialHouseSourceName : '暂无' }}</span>
                    </div>
                </div>
                <div class="text-line">
                    <div class="text-left">
                        <span class="span1">面积：</span>
                        <span class="span2">{{ item.builtUpArea ? item.builtUpArea+'m²' : '暂无'}}</span>
                    </div>
                    <div class="text-right">
                        <span class="span1">朝向：</span>
                        <span class="span2">{{ item.orientationName ? item.orientationName : '暂无'}}</span>
                    </div>
                </div>
                <div class="text-line">
                    <div class="house-remarks">
                        <span class="span1">房屋描述：</span>
                        <span class="span2">{{  item.remarks ? item.remarks : '暂无'}}</span>
                    </div>
                </div>
            </div>
            <!--        收房信息-->
            <div class="potential-house">收房信息</div>
            <div class="house-details">
                <div class="text-line">
                    <div class="text-left">
                        <span class="span1">状态：</span>
                        <span class="span2">{{ item.houseType }}</span>
                    </div>
                    <div class="text-right">
                        <span class="span1">改造数量：</span>
                        <span class="span2">
                            {{item.changHouseNum ? item.changHouseNum : '0'}} + {{item.bedroomCount ? item.bedroomCount : '0'}}
<!--                            {{item.changHouseNum ? item.bedroomCount+'+'+item.changHouseNum : (item.bedroomCount ? item.bedroomCount : '0')+'+'+'0'}}-->
                        </span>
                    </div>
                </div>
                <div class="text-line">
                    <div class="text-left">
                        <span class="span1">装修风格：</span>
                        <span class="span2">{{ item.decorationStyle ? item.decorationStyle : '暂无'}}</span>
                    </div>
                    <div class="text-right">
                        <span class="span1">装修预算：</span>
                        <span class="span2">{{  item.decorationMoney ? item.decorationMoney  + '元': '暂无' }}</span>
                    </div>
                </div>
                <div class="text-line">
                    <div class="text-left">
                        <span class="span1">总免租期：</span>
                        <span class="span2">{{ item.noFeeTime ? item.noFeeTime  + '天': '暂无'}}</span>
                    </div>
                    <div class="text-right">
                        <span class="span1">总装修期：</span>
                        <span class="span2">{{  item.renovationTime ? item.renovationTime + '天': '暂无' }}</span>
                    </div>
                </div>
                <div class="text-line">
                    <div class="house-remarks">
                        <span class="span1">收房描述：</span>
                        <span class="span2">{{ item.houseRemarks ? item.houseRemarks : '暂无'}}</span>
                    </div>
                </div>
            </div>

            <!-- 跟进信息-->
            <div class="real-info" >跟进信息</div>
            <div class="real-details" >
                <div class="bgPoint">
                    <div class="topVerticalBlue"></div>
                    <div class="outerCircleBlue">
                        <div class="insideCircleBlue"></div>
                    </div>
                    <div class="bottomVerticalBlue"></div>
                </div>
                <div class="real-details-middle"  v-if="item.followUpOrders">
                    <div class="middle-top">
                        <div class="middle-top-text-history">跟进</div>
                        <div class="middle-top-time">{{item.followUpOrders && item.followUpOrders.addTime}}</div>
                    </div>
                    <div class="middle-bottom">「{{item.followUpOrders && item.followUpOrders.staffName}}」{{item.followUpOrders && item.followUpOrders.followUpDetail}}</div>
                </div>

                <div class="noInfo" v-else>
                        <span class="span2">暂无信息</span>
                </div>
                <div class="real-details-right"  v-if="checkAuthList(authButtonsList,'followUp')">
                    <div class="look-real" @click="followHistoryDetail({row:item})">历史跟进</div>
                </div>
            </div>
            <!-- 实勘信息-->
            <div v-show="isShow">
            <div class="real-info" >实勘信息</div>
            <div class="real-details"  >
                <div class="bgPoint">
                    <div class="topVerticalRed"></div>
                    <div class="outerCircleRed">
                        <div class="insideCircleRed"></div>
                    </div>
                    <div class="bottomVerticalRed"></div>
                </div>
                <div class="real-details-middle" v-if="realHouseList.addTime!=''||realHouseList.staffName!=''||realHouseList.explorationDescription!=''">
                    <div class="middle-top">
                        <div class="middle-top-text">实勘</div>
                        <div class="middle-top-time">{{ realHouseList.addTime }}</div>
                    </div>
                    <div class="middle-bottom">「{{ realHouseList.staffName }}」{{ realHouseList.explorationDescription }}</div>
                </div>
                <div class="noInfo" v-else>
                    <span class="span2">暂无信息</span>
                </div>
                <div class="real-details-right" @click="PracticalExplorationClick" v-if="checkAuthList(authButtonsList,'realExploration')">
                    <div class="look-real">查看实勘</div>
                </div>
            </div>

            </div>
            <div class="bottom" v-if="item.resourceStatus==1 && houseType!='public'"  >
<!--                有效显示签约-->
                <div :class="item.houseTypeCode==2 ? 'sign' :'signhidden'" @click="signClick">
                    <div class="sign-icon" ></div>
                    <div class="sign-text"  >签约</div>
                </div>
                <div v-if="checkAuthList(authButtonsList,'applicationRoom')"  :class="item.houseTypeCode==2||item.houseTypeCode==1 ? 'houseApplayHidden' :'houseApplay'"   @click="potentialHouseApply({row : item})">
                   <button>收房申请</button>
                </div>
                <div class="modifyDiv">
                <div @click="modification(item)" class="modify" v-if="checkAuthList(authButtonsList,'modify')">修改</div>
                </div>
            </div>
<!--           &&potentialDetail==true -->
            <div class="btns_row" v-if=" item.resourceStatus!=3">
                <button   class="setBtn" v-if="item.resourceStatus == '1'&&potentialDetail==true&&checkAuthList(authButtonsList,'invalidOrEffective')" @click="showPopupWithInvalid({row:item})">设为无效</button>
                <button   class="setBtn valid" v-if="item.resourceStatus == '2'&&potentialDetail==true&&checkAuthList(authButtonsList,'effective')" @click="showPopupWithInvalid({row:item})">设为有效</button>
                <button  :class="potentialDetail ? 'moreBtn' : 'moreBtnPlc'" v-if="item.resourceBelog==1&&checkAuthList(authButtonsList,'switchToPrivateDisk')" @click="showPopupWithPrv({row:item})" >转为私盘</button>
                <button  class="moreBtn" v-if="item.resourceBelog==0&&checkAuthList(authButtonsList,'switchToPublicDisk')" @click="showPopupWithPlc({row:item})" >转为公盘</button>
            </div>
        </div>
        <van-popup v-model="maskShow" position="bottom">
            <!-- 转为私盘 -->
            <div class="popup_wrapper" v-if="prvPopup == true ">
                <p class="popup_title">
                    <span class="colon_bg"></span>
                    <span  class="colon_mes">将公盘转为私盘</span>
                </p>
                <div class="drop_menu_wrapper">
                    <div class="left_menu">
                        维护人
                    </div>
                    <div class="right_menu" >
<!--                        <select class=" staffName" v-model="staffList.text" @change="changeStaffName($event)">-->
<!--                            <option :value=" item.value"  v-for="item in staffList" :key="item.id"  >-->
<!--                                {{ item.text}}-->
<!--                            </option>-->
<!--                        </select>-->
                        <div class="downMenu" :value="value"  @click="showPicker = true">
                            <div class="downMenuValue">{{value}}</div>
                            <div class="arrows"></div>
                        </div>
                    </div>
                    <div class="arrows"></div>
                </div>
                <div class="sub_btn" v-if="checkAuthList(authButtonsList,'Assign')">
                    <van-button class="sub_button" size="large" @click="publicToPrivate">提交</van-button>
                </div>
            </div>
            <div class="popup_wrapper esc" v-if="sureChange">
                <p class="popup_title" v-if="isvoide == 2  ">
                    <span class="colon_bg"></span>
                    <span class="colon_mes">确认将该潜房信息转为有效</span>
                </p>
                <p class="popup_title" v-else>
                    <span class="colon_bg" ></span>
                    <span  class="colon_mes">确认将该潜房信息转为无效</span>
                </p>
                <div class="sub_btn">
                    <van-button class="sub_button" size="large" @click="changeState()">确认</van-button>
                </div>
            </div>
<!--            转为公盘-->
            <div class="popup_wrapper" v-if="priPopup == true">
                <p class="popup_title">
                    <span class="colon_bg"></span>
                    <span  class="colon_mes">将私盘转为公盘</span>
                </p>
                <div class="sub_btn">
                    <van-button class="sub_button" size="large" @click="privateToPublic">提交</van-button>
                </div>
            </div>
            <!-- 转为无效 -->
        </van-popup>
<!--        维护人弹窗-->
        <van-popup v-model="showPicker" round position="bottom">
            <van-picker  show-toolbar :columns="staffList" value-key="text" @cancel="showPicker = false" @confirm="changeStaffName"/>
        </van-popup>
        <meta name="viewport" content="initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no, width=device-width,viewport-fit=cover">
    </div>
</template>

<script>
    import { NavBar,popup,button,picker } from 'vant'
    import {getStaffId, globaluserId, responseUtil} from '../../../libs/rongxunUtil';
    import {
        changeResourceStatus, followUpList, queryExploration,
        queryPotentialHouse, queryStaffList, updatePotentialResourceBelog
    } from '../../../getData/getData';
    import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
    import { getStaffFuntionModelList } from "../../../getData/getData";
    import { checkAuthList } from "../../../libs/rongxunUtil";
    export default {
        data(){
            return {
                value : '',
                //下拉
                showPicker : false,
                //权限数组
                authButtonsList:[],
                potentialDetail : false,
                sureChange : false,
                isShow :true,
                //跟踪信息路由返回值
                followUpOrders :{},
                //获取员工ID
                getStaffNameId: '',
                poprow: {},
                isvoide: 0,
                prvPopup: false,
                maskShow: false,
                priPopup: false,
                priMaskShow: false,
                //房屋具体信息
                houseType: '',
                house:[
                    //
                    // {
                    //     followUpOrders : {
                    //         staffName : '',
                    //         addTime : '',
                    //         followUpDetail : ''
                    //     },
                    //
                    // }
                ],
                puTopr :{
                },
                //实勘信息列表
                realHouseList:{
                    addTime : '',
                    staffName : '',
                    explorationDescription : '',
                }
                ,
                //收房信息列表
                checkInformationList:[
                    {
                        state:'',
                        sumTime:'',
                        leaseMoney:'',
                        decorationTime:''
                    }
                ],
                staffList :[
                    {
                        text : '',
                        value : '',
                    }
                ]
            }
        },
        components: {
            [NavBar.name]: NavBar,
            [popup.name]: popup,
            [button.name]: button,
            rxNavBar,
            [picker.name]: picker,
        },
        created() {
            this.id = this.$route.query.id
            this.idList=this.$route.query.idList
            this.houseType = this.$route.query.houseType
            this.initData()
            //如果是私盘初始化有实勘详情
            if(this.$route.query.houseType=='private'){
                this.explorationInit()
            }
            this.getStaffFuntionModelList()
        },
        mounted() {
        },
        methods:{
            //权限方法
            getStaffFuntionModelList () {
                var that = this
                let data = {}
                data.staff_id = getStaffId()
                data.menuName = 'house_index'
                getStaffFuntionModelList(data).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        that.authButtonsList = response.data.data.data
                    })
                })
            },
            checkAuthList,
            //获取公转私下拉菜单员工人名的方法
            // changeStaffName(event){
            //     this.getStaffNameId=event.target.value
            // },
            changeStaffName(value) {
                this.value = value.text;
                this.getStaffNameId = value.value
                this.showPicker = false;
            },
            //点击改变资源状态接口 changeState({row:i})
            changeState(){
                this.poprow.resourceStatus == 1 ? this.poprow.resourceStatus= 2:this.poprow.resourceStatus = 1
                var that = this
                let initData = {}
                initData.user_id = globaluserId()
                initData.id=that.poprow.prId
                initData.idList=that.poprow.prIdList
                initData.resourceStatus = that.poprow.resourceStatus
                changeResourceStatus(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        if(that.poprow.resourceStatus==1){
                            responseUtil.alertMsg(that,'转为有效成功')
                        }else{
                            responseUtil.alertMsg(that,'转为无效成功')
                        }
                        that.maskShow = false
                        that.prvPopup=false
                        that.sureChange = false
                        that.poprow ={}
                        that.house=[]
                        that.initData()
                    })
                })
            },
            //  私盘转公盘点击提交的方法
            privateToPublic(){
                this.puTopr.resourceBelog==0 ? this.puTopr.resourceBelog=1:this.puTopr.resourceBelog=0
                var that = this
                let initchange = {}
                initchange.staff_id= getStaffId()
                initchange.user_id=globaluserId()
                initchange.idList=that.puTopr.prId
                initchange.id=that.puTopr.prId
                initchange.resourceBelog = that.puTopr.resourceBelog
                updatePotentialResourceBelog(initchange).then(function (response) {
                    responseUtil.dealResponse(that,response,() => {
                        that.MaskShow = false
                        that.priPopup = false
                        that.prvPopup = false
                        that.house = []
                        responseUtil.alertMsg(that,'成功转为公盘')
                        that.leftReturn();
                    })
                })
            },
            //  公盘转私盘点击提交的方法
            publicToPrivate(){
                this.puTopr.resourceBelog==1 ? this.puTopr.resourceBelog=0:this.puTopr.resourceBelog=1
                var that = this
                let initchange = {}
                initchange.staff_id= getStaffId()
                initchange.user_id=that.getStaffNameId
                initchange.idList=that.puTopr.prId
                initchange.id=that.puTopr.prId
                initchange.resourceBelog = that.puTopr.resourceBelog
                updatePotentialResourceBelog(initchange).then(function (response) {
                    responseUtil.dealResponse(that,response,() => {
                        that.maskShow = false
                        that.prvPopup = false
                        that.priPopup = false
                        that.leftReturn();
                    })
                })
            },
            leftReturn(){
                this.$router.go(-1)
            },
            //私盘转公盘
            showPopupWithPlc(e){
                let that =this
                that.sureChange = false
                that.maskShow = true
                that.priPopup = true
                that.puTopr=e.row
            },
    //  公盘转私盘点击按钮获取用户的方法
            showPopupWithPrv(e){
                let that =this
                that.sureChange = false
                that.maskShow = true
                that.prvPopup = true
                that.puTopr=e.row
                let initPersion = {}
                initPersion.department_id=''
                initPersion.id=''
                initPersion.searchName=''
                initPersion.departmentDown_id=''
                queryStaffList(initPersion).then(function (response) {
                    responseUtil.dealResponse(that,response,() => {
                        that.staffList =response.data.data.data
                        for(let i = 0; i < that.staffList.length;i++){
                            that.staffList[i].text = response.data.data.data[i].staffName;
                            that.staffList[i].value = response.data.data.data[i].id;
                        }
                    })
                })
            },
            //  有效无效的弹窗显示
            showPopupWithInvalid(e){
                this.poprow=e.row
                this.isvoide = e.row.resourceStatus
                this.sureChange = true
                this.maskShow = true
                this.prvPopup = false
                this.priPopup = false
            },
            //初始化数据
            initData:function(){
                var that = this
                let initData = {}
                initData.staff_id = getStaffId()
                initData.id = this.id
                initData.idList =this.idList
                queryPotentialHouse(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        let data = response.data.data.data || response.data.data
                        if(that.houseType=='private'){
                            that.house.push(data)
                            that.potentialDetail=true
                            //初始化跟进信息
                            that.init()
                        }else{
                            that.house=data
                            for(let i=0;i<that.house.length;i++){
                                that.initPlcFollow(that.house[i].prId,i)
                            }
                            that.isShow=false
                        }
                    })
                })
            },
            //实勘详情接口
            explorationInit(){
                let that=this
                let initData = {}
                initData.user_id=globaluserId()
                initData.id= this.id
                queryExploration(initData).then(function (response) {
                    responseUtil.dealResponse(that,response,() =>{
                        if(response.data.data.explorationList.length!=0){
                            that.realHouseList=response.data.data.explorationList[0]
                        }
                    })
                })
            },
            //跟进记录公盘
            initPlcFollow: function (prId,i) {
                let that = this
                let initData = {}
                initData.followUpItem_id = prId,
                    initData.staff_id = getStaffId(),
                    initData.followUpType = '1'
                    followUpList(initData).then(function (response) {
                        responseUtil.dealResponse(that, response, () => {
                            if(response.data.data.followUpList.length>0){
                                that.$set(that.house[i],'followUpOrders',response.data.data.followUpList[0])
                            }
                        })
                    })
            },
            //跟进记录私盘
            init: function () {
                let that = this
                let initData = {}
                initData.followUpItem_id = that.id,
                initData.staff_id = getStaffId(),
                initData.followUpType = '1'
                followUpList(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        if(response.data.data.followUpList.length>0){
                            that.$set(that.house[0],'followUpOrders',response.data.data.followUpList[0])
                        }
                    })
                })
            },
            //跟进信息
            followHistoryDetail(e){
                this.$router.push({
                    name : 'addFollowUp',
                    query : {
                        id : e.row.prId,
                        followUpType : '1'
                    }
                })
            },
            //签约业主合同
            signClick(){
                this.$router.push({
                    name: 'newOwnerContract',
                    params: {
                        prId: this.house[0].prId,
                        mobile : this.house[0].mobile,
                        flagType : 0,
                        address : this.house[0].address,
                        estateRoom_id : this.house[0].estateRoom_id,
                        builtUpArea:this.house[0].builtUpArea,
                        orientation_id:this.house[0].applyorientationId,
                        applyorientationName:this.house[0].applyorientationName,
                        city_id:this.house[0].city_id,
                        houseRoomCount:this.house[0].houseRoomCount
                    }
                })
            },

            //查看实勘
            PracticalExplorationClick(){
                this.$router.push({
                    name:'practicalExplorationInfo',
                    query:{
                        id:this.id,
                    }
                });
            },

            //修改
            modification(item) {
                this.$router.push({
                    name: 'RegisterPotentialHouse',
                    query: {
                        prId: item.prId
                    }
                })
            },

            //收房申请
            potentialHouseApply(e){
                this.$router.push({
                    name:'ApplicationForRehousing',
                    query:{
                        id:e.row.id,
                        //房源地址
                        houseAddress:e.row.address,
                        //房间数量
                        bedroomCount:e.row.bedroomCount,
                        //改造数量
                        changHouseNum:e.row.changHouseNum,
                        //租金
                        rentMoney:e.row.rentMoney,
                        //朝向ID
                        orientation_id:e.row.orientation_id,
                        //朝向
                        orientationName:e.row.orientationName,
                        //面积
                        builtUpArea:e.row.builtUpArea,
                        //装修风格
                        decorationStyle :e.row.decorationStyle,
                        //装修预算
                        decorationMoney:e.row.decorationMoney,
                        //收房描述
                        houseRemarks:e.row.remarks,
                        // 栋ID
                        estatePedestal_id:e.row.estatePedestal_id,
                        //楼盘Id
                        estate_id:e.row.estate_id,
                        //单元ID
                        estateUnit_id:e.row.estateUnit_id,
                        //房间ID
                        estateRoom_id:e.row.estateRoom_id,
                    }
                });

            }
        }
    }
</script>

<style scoped lang="less">

    .potentialDetailPlc{
        padding-bottom: constant(safe-area-inset-bottom);
        padding-bottom: env(safe-area-inset-bottom);
    }
    .bgPoint{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 30px;
        /*border: 1px solid red;*/
        .outerCircleRed{
            height:10px;
            width: 10px;
            border-radius: 50%;
            border:2px solid #FF5D3B;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;
            .insideCircleRed{
                border: 3px solid #FF5D3B;
                border-radius: 50%;
            }
        }
        .topVerticalRed{
            height: 25%;
            width: 1.5px;
            border: none;
            background-image: linear-gradient(to bottom,#FFE4B5 , #FF5D3B);
        }
        .bottomVerticalRed{
            height:25%;
            width: 1.5px;
            border: none;
            background-image: linear-gradient(to top,#FFE4B5 , #FF5D3B);
        }
        .outerCircleBlue{
            height:10px;
            width: 10px;
            border-radius: 50%;
            border:2px solid #3191FF;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;
            .insideCircleBlue{
                border: 3px solid #3191FF;
                border-radius: 50%;
            }
        }
        .topVerticalBlue{
            height: 25%;
            width: 1.5px;
            border: none;
            background-image: linear-gradient(to bottom,#C6E2FF , #3191FF);
        }
        .bottomVerticalBlue{
            height:25%;
            width: 1.5px;
            border: none;
            background-image: linear-gradient(to top,#C6E2FF , #3191FF);
        }
    }

    .staffName{
        width: 100%;
        border: none;
        background-color: #FFFFFF;
    }
    // 公盘转私盘
    .popup_wrapper {
        width:345px;
        padding: 15px;
        background-color: rgb(250,250,250);
        &.esc{
            background-color: rgb(255,255,255);
        }
        .invalid_message{
            .msg_body{
                border-radius: 8px;
                background-color: rgb(250,250,250);
            }
        }
        .colon_bg{
            position: absolute;
            display: block;
            width: 16px;
            height: 16px;
            background:url(../../../assets/images/colon.png) no-repeat;
            background-size: 100% 100%;
        }
        .colon_mes{
            display: inline-block;
            padding-left: 25px;
        }
        .popup_title{
            width:168px;
            font-size: 16px;
            font-weight: bold;
            margin: 20px 0;
        }
        .drop_menu_wrapper{
            height: 30px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 15px;
            background-color: rgb(255,255,255);
            border-radius: 8px;
            flex-wrap: nowrap;
            .left_menu{
                font-weight: bolder;
                width: 80px;
                position: relative;
                font-size: 14px;
                &::before{
                    content: "";
                    display: inline-block;
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    background-color: rgb(251,91,64);
                    position: relative;
                    top: -2px;
                    overflow: hidden;
                    margin-right: 5px;
                }
                &::after{
                    content: "";
                    display: inline-block;
                    position: absolute;
                    width: 2px;
                    height: 12px;
                    background-color: rgb(250,250,250);
                    right: 0;
                    top: 25%;
                    /*margin-top: -6px;*/
                }
            }
            .right_menu{
                width: 100%;
                /*height: 30px;*/
                .downMenu{
                    width: 100% ;
                    height: 30px;
                    /*line-height: 30px;*/
                    display: flex;
                    align-items: center;
                    .downMenuValue{
                        width: 95%;
                        font-size: 14px;
                    }
                    .arrows{
                        width: 0;
                        height: 0;
                        border: 3.5px solid;
                        border-color: black transparent transparent transparent;
                        margin-top: 5px;
                    }
                }
            }

        }
        .sub_btn{
            margin-top: 50px;
            .sub_button{
                border-radius: 8px;
                color: rgb(255,255,255);
                border: none;
                background: linear-gradient(rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
                background: -webkit-linear-gradient(left, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
                /* Safari 5.1 - 6.0 */
                background: -o-linear-gradient(right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
                /* Opera 11.1 - 12.0 */
                background: -moz-linear-gradient(right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
                /* Firefox 3.6 - 15 */
                background: linear-gradient(to right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
            }


        }
    }


    .btns_row{
        width: 88%;
        margin: 15px auto ;
    }
    .btns_row_plc{
        width: 92%;
        margin: 15px auto ;
    }

    .setBtn,.valid,.moreBtn,.moreBtnPlc{
        font-size: 13px;
        height: 35px;
        width: 90px;
        line-height: 33px;
        border-radius: 8px;
        border: none;
    }

    .setBtn{
        color: #bcbcbc;
        background-color: #f1f1f1;
    }
    .valid{
        color: rgba(255, 93, 59, 1);
        background-color: #fbded5;
    }
    .moreBtnPlc,.moreBtn{
        color: #FF5D3B;
        background-image:linear-gradient(white,white),
        linear-gradient(to right,#FFC274,#FF5D3B);
        background-clip: padding-box,border-box;
        background-origin: border-box;
        border: 1px solid transparent;
    }
    .moreBtnPlc{
        margin-left: 73%;
    }
    .moreBtn{
        margin-left: 45%;
    }
    .potentialDetail{
        padding-bottom: 70px;
        padding-bottom: constant(safe-area-inset-bottom);
        /*padding-bottom: env(safe-area-inset-bottom);*/

    }
    .owner-info{
        font-size: 12px;
        height: 17px;
        line-height: 17px;
        margin: 20px 0 0 15px;
        color: rgba(255, 93, 59, 1);
        text-align: left;
        font-family: PingFangSC-Semibold;
    }
    .owner-infos{
        background-color: white;
        border-radius: 8px;
        margin: 5px 15px;
        height: auto;
        /*padding:  ;*/
        padding: 0px 0px 18px;
    }
    .noInfo{
            width: 57%;
        margin-left: 15px;
        text-align: center;
        line-height: 110px;
    }
    .text-line{
        display: flex;
        align-items: center;
        /*height: 24px;*/
        margin-left:18px ;
        /*width: 170px;*/
    }
    .text-line .text-left{
        display: flex;
        /*width: 150px;*/
        /*height: 22px;*/
        width: 45%;
        line-height: 24px;
        align-items: center;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .text-line .house-remarks{
        /*display: flex;*/
        width: 97%;
        line-height: 24px;
        align-items: center;
    }
    .text-line .text-right{
        display: flex;
        margin-left: 10px;
        width: 55%;
        /*height: 22px;*/
        line-height: 24px;
        align-items: center;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .span1{
        font-size: 14px;
        font-weight: bold;
        color: black;
        line-height: 25px;

    }
    .span2{
        font-size: 13px;
        color: #999999;
        line-height: 24px;
        font-weight: normal;
    }
    .span3{
        font-size: 14px;
        color: #999999;
        line-height: 23px;
        margin: 0 5px;
    }
    .house-info{
        color: red;
        font-size: 12px;
        margin: 30px 0 0 15px;

    }
    .house-infos{
        background-color: white;
        border-radius: 8px;
        margin: 5px 15px 0 15px;
        height: auto;
        /*padding: 18px;*/
        padding: 18px 0px;
    }
    .house-infos .infos{
        display: flex;
        align-items: center;
        height: 23px;
        margin-left: 18px;
    }
    /*.house-area{*/
    /*    width: 309px;*/
    /*    height: auto;*/
    /*    line-height: 22px;*/
    /*}*/
    .house-area{
        width: 309px;
        height: auto;
        line-height: 23px;
        font-size: 14px;
        font-weight: bold;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        margin-left: 18px;
    }
    .potential-house{
        line-height: 17px;
        height: 17px;
        margin: 30px 0 5px 15px;
        color: rgba(255, 93, 59, 1);
        font-size: 12px;
        text-align: left;
        font-family: PingFangSC-Semibold;
    }
    .house-details{
        background-color: white;
        border-radius: 8px;
        margin: 0px 15px 0 15px;
        height: auto;
        /*padding: 18px;*/
        padding: 18px 0px;
    }
    .house-describe{
        background-color: white;
        border-radius: 8px;
        margin: 15px 15px 0 15px;
        height: auto;
        padding: 18px;
    }
    .house-describe .text{
        width: auto;
        height: 22px;
        font-size: 14px;
        color: black;
        line-height: 22px;
        font-weight: bold;
    }
    .house-describe .text-detail{
        width: 309px;
        font-size: 12px;
        color: #999999;
        line-height: 22px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    .real-info{
        color: red;
        font-size: 12px;
        margin: 30px 0 0 15px;
    }
    .real-details{
        background-color: white;
        border-radius: 8px;
        margin: 5px 15px 5px 15px;
        display: flex;
    }
    .real-details-middle{
        width: 60%;
        margin-left: 5px;
        margin-top: 30px;
    }
    .middle-top{
        height: 20px;
        display: flex;
        align-items: center;
    }
    .middle-top-text-history{
        width: 40px;
        height: 16px;
        font-size: 12px;
        text-align: center;
        line-height: 18px;
        border-radius: 10px;
        background-color: #D6E9FF;
        color: #3191FF;
    }
    .middle-top-text{
        width: 40px;
        height: 16px;
        font-size: 12px;
        text-align: center;
        line-height: 18px;
        border-radius: 10px;
        background-color: pink;
        color: #ff784e;
    }
    .middle-top-time{
        height: 20px;
        margin-left: 10px;
        font-size: 12px;
        text-align: left;
        line-height: 22px;
        color: #999999;
    }
    .middle-bottom{
        word-wrap: break-word;
        margin-top: 5px;
        line-height: 20px;
        font-size: 12px;
        color: #999999;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    .real-details-right{
        margin-left:10px ;
    }
    .look-real{
        width: 80px;
        height: 30px;
        font-size: 12px;
        text-align: center;
        line-height: 32px;
        color: #FF5D3B;
        margin-top: 40px;
        /*margin-right: 20px;*/
        margin-bottom: 40px;
        border-radius: 8px;
        border: 1px solid transparent;
        background-image: linear-gradient(white,white),linear-gradient(to right,#ffd59e,#ffa08a);
        background-clip: padding-box,border-box;
        background-origin: border-box;
    }
    .history-takeHouse{
        width: 100%;
        overflow: hidden;
    }
    .history-takeHouse .history{
        float: right;
        height: 12px;
        font-size: 12px;
        line-height: 12px;
        color: #3596ff;
        margin-right: 15px;
        margin-top: 5px;
    }
    a{
        text-decoration: underline;
    }
    .history-takeHouse .takeHouse{
        float: left;
        height: 12px;
        font-size: 12px;
        line-height: 12px;
        color: red;
        margin-left: 15px;
        margin-top: 30px;
    }
    .takeHouse-infos{
        margin-bottom: 71px;
    }
    .takeHouse-info{
        margin: 5px 15px 10px 15px;
        background-color: white;
        padding: 18px;
    }
    .wait-approve{
        width: 46px;
        height: 14px;
        line-height: 14px;
        margin-top: -5px;
        font-size: 12px;
        text-align: center;
        padding-top: 2px;
        color: white;
        border-radius: 8px 0;
        background: linear-gradient(to right,#ffb96f,#ff6d44);
    }
    .bottom{
        position: fixed;
        bottom: 0px;
        width: 100%;
        height: 70px;
        border-top: 1px solid #d0d0d0;
        background-color: #f8f8f8;
        z-index: 99;
        display: flex;
        justify-content: flex-start;
        padding-bottom: env(safe-area-inset-bottom);
    }
    .leftTopValid,.leftTopInvalid,.leftTopsign{
        width: 35px;
        height: 13px;
        text-align: center;
        line-height: 0.4rem;
        font-size: 10px;
        color: white;
        border-radius: 6px 0;
    }
    .leftTopsign{
        background: linear-gradient(to right,#2900CC,#5C33FF);
    }
    .leftTopValid{
        background: linear-gradient(to right,#64cb94,#2dd3af);
    }
    .leftTopInvalid{
        background-color: #999999;
    }
    .bottom .sign{
        /*flex: 0.5;*/
        width: 26px;
        margin-top: 17px;
        margin-left: 35px;
    }
    .signhidden{
        width: 61px;
        visibility: hidden;
    }
    .houseApplayHidden{
        width: 175px;
        visibility: hidden;
    }
    .bottom .sign .sign-icon{
        margin-left: 3px;
        width: 20px;
        height: 20px;
        background-image: url("../../../assets/images/potentialHouse-icon1.png");
        background-position: center;
        background-size: 100%;
        background-repeat: no-repeat;
    }
    .bottom .sign .sign-text{
        width: 100%;
        height: 20px;
        margin-top: 5px;
        text-align: center;
        line-height: 20px;
        color: black;
        font-size: 12px;
        white-space: nowrap;
    }
    .bottom .houseApplay{
        /*flex: 1;*/
        width: 175px;
        /*height: 44px;*/
        margin-top: 13px;
        /*margin-left: 50px;*/
        color: white;
        text-align: right;
        font-size: 16px;
        button{
            height: 44px;
            border-radius: 8px;
            width: 100px;
            background: linear-gradient(to right,#79c5ff,#3596ff);
            line-height: 40px;
            border: none;
        }
    }
    .modifyDiv{
        width: 120px ;
        padding-left: 20px;
    }
    .bottom .modify{
        /*flex: 1;*/
        width: 100px;
        height: 44px;
        margin-top: 13px;
        /*margin-left: 20px;*/
        border-radius: 8px;
        background: linear-gradient(to right,#ffbd6f,#fe6641);
        color: white;
        line-height: 44px;
        text-align: center;
        font-size: 16px;
    }
    .noHaveInfo{
        font-size: 12px;
    }
    //维护人的下拉

</style>
